<template>
    <div>
        <full-overlay :show="loading" />
        <b-button-group>
            <b-button :to="{ name: 'Participant' }">К списку</b-button>
            <b-button variant="success" @click="add">Добавить</b-button>
            <b-button variant="primary" @click="getList(1)">Обновить</b-button>
        </b-button-group>
        <b-table :items="list" :fields="fields" striped class="mt-3">
            <template #cell(name)="{ value }">
                <div>{{ value.ru }}</div>
                <div>{{ value.en }}</div>
            </template>
            <template #cell(settings)="{ item: { id, festival } }">
                <b-button-group>
                    <b-button @click="edit(id)">Редактировать</b-button>
                    <b-button @click="content(id)">Описание</b-button>
                    <b-dropdown text="Фестивали">
                        <b-dropdown-item
                            v-for="f in festivals"
                            :key="f.id"
                            @click="attach(id, f.id)"
                            :disabled="festival.includes(f.id)"
                        >
                            {{ f.id }}
                        </b-dropdown-item>
                    </b-dropdown>
                </b-button-group>
            </template>
        </b-table>
        <b-pagination
            :value="pg"
            :total-rows="hasNext ? pg + 1 : pg"
            per-page="1"
            @change="getList"
            limit="1"
            hide-goto-end-buttons
            prev-text="Назад"
            next-text="Вперед"
        />
        <b-modal
            title="Редактирование"
            v-model="modal.show"
            hide-footer
            size="xl"
        >
            <b-form @submit.prevent="send">
                <b-form-row>
                    <b-col>
                        <b-form-group label="Имя">
                            <b-form-input v-model="modal.name.ru" required />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="[EN] Имя">
                            <b-form-input v-model="modal.name.en" />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Дата выхода">
                            <b-form-datepicker
                                v-model="modal.created"
                                required
                            />
                        </b-form-group>
                    </b-col>
                    <b-form-group label="Возрастное ограничение">
                        <b-form-input v-model="modal.age" />
                    </b-form-group>
                </b-form-row>
                <b-form-row>
                    <b-col>
                        <b-form-group label="Описание">
                            <b-form-textarea
                                v-model="modal.description.ru"
                                required
                            />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="[EN] Описание">
                            <b-form-textarea v-model="modal.description.en" />
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-group label="Загрузка постера">
                    <b-form-file
                        v-model="modal.poster"
                        accept="image/jpeg, image/png"
                        :required="modal.id == 0"
                    />
                </b-form-group>
                <div class="poster" v-if="modal.id > 0">
                    <img
                        :src="`${poster}/${modal.id}/poster.jpg?${Date.now()}`"
                    />
                </div>
                <b-button type="submit" class="mt-1">Сохранить</b-button>
            </b-form>
        </b-modal>
        <content-editor
            ref="editor"
            get-url="/spectacle/:id/content"
            send-url="/spectacle/:id/content"
            :media-url="poster"
        />
    </div>
</template> 

<style lang="scss" scoped>
.poster {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    img {
        max-width: 700px;
        max-height: 600px;
    }
}
</style>

<script>
import FullOverlay from "@c/FullOverlay.vue";
import ContentEditor from "@c/ContentEditor.vue";
export default {
    name: "Spectacle",
    components: {
        FullOverlay,
        ContentEditor,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            list: [],
            poster: "",
            fields: [
                {
                    key: "id",
                    label: "#",
                },
                {
                    key: "name",
                    label: "Имя",
                },
                {
                    key: "settings",
                    label: "",
                },
            ],
            pg: 1,
            hasNext: false,
            modal: this.getModal(),
            festivals: [],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        async edit(id) {
            this.loading = true;
            const { one } = await this.$fetchAdmin(
                `/spectacle/${id}`
            ).then((r) => r.json());
            this.modal = Object.assign(this.getModal(), one);
            this.modal.show = true;
            this.loading = false;
        },
        content(id) {
            this.$refs.editor.open(id);
        },
        getModal: () => ({
            id: 0,
            name: {
                ru: "",
                en: "",
            },
            description: {
                ru: "",
                en: "",
            },
            created: "",
            age: "",
            poster: null,
            show: false,
        }),
        add() {
            this.modal = this.getModal();
            this.modal.show = true;
        },
        async send() {
            this.loading = true;
            const body = new FormData();
            body.append("id", this.modal.id);
            body.append("created", this.modal.created);
            body.append("age", this.modal.age ?? "");
            body.append("name[ru]", this.modal.name.ru);
            body.append("name[en]", this.modal.name.en);
            body.append("description[ru]", this.modal.description.ru);
            body.append("description[en]", this.modal.description.en);
            if (this.modal.poster) {
                body.append("poster", this.modal.poster);
            }
            await this.$fetchAdmin(`/spectacle/${this.id}/save`, {
                method: "POST",
                body,
            }).then((r) => r.json());
            this.loading = false;
            this.modal.show = false;
            this.getList();
        },
        async getList(pg = 1) {
            this.loading = true;
            const { list, poster, hasNext, festivals } = await this.$fetchAdmin(
                `/spectacle/${this.id}/list?pg=${pg}`
            ).then((r) => r.json());
            this.list = list;
            this.festivals = festivals;
            this.poster = `${this.$store.state.api}${poster}`;
            this.hasNext = hasNext;
            this.pg = pg;
            this.loading = false;
        },
        async attach(id, f_id) {
            this.loading = true;
            const body = new FormData();
            body.append("id", f_id);
            await this.$fetchAdmin(`/spectacle/${id}/attach`, {
                method: "POST",
                body,
            }).then((r) => r.json());
            this.loading = false;
            for (let l of this.list) {
                if (l.id == id) {
                    l.festival.push(f_id);
                    break;
                }
            }
        },
    },
};
</script>